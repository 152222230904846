import axios from "axios";

const contact = {
  actions: {
    async submitContactForm(context, body) {
      return await axios
        .post(
          `${context.rootState.url}quote/submit/contact_form/submitter`,
          body
        )
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async getReasons(context) {
      return await axios
        .get(`${context.rootState.url}web/reason`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async submitQuoteRequest(context, { body, files }) {
      let form = new FormData();
      form.append("file_count", files.length);
      files.forEach((file, index) => {
        const fileObject = new File(
          [file[0]],
          file[0]?.name.replace(/[^0-9a-z. ]/gi, ""),
          {
            type: file[0]?.type,
          }
        );
        form.append(`file_${index}`, fileObject);
      });
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      return await axios
        .post(
          `${context.rootState.url}quote/submit/intake_quote/submitter`,
          form,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async getIntakeLists(context) {
      return await axios
        .get(`${context.rootState.url}web/intake`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
  },
};

export default contact;
